<template>
  <c-box
    flex-grow="1"
    class="container"
  >
    <c-breadcrumb
      font-size="14px"
      line-height="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link is-current>
          Manajemen Log Activity
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />

    <c-flex
      mb="33px"
      justify="space-between"
      align="center"
    >
      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
      >
        Manajemen Log Activity
      </c-heading>
    </c-flex>
    <c-box
      background="#FFFFFF"
      border="1px solid #F2F2F2"
      box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
      border-radius="12px"
      class="container"
    >
      <c-flex
        py="25px"
        px="27px"
        border-bottom="1px solid #C4C4C4"
      >
        <c-flex
          justify="space-between"
          align="center"
          flex-grow="1"
        >
          <c-heading
            as="h3"
            font-family="Montserrat"
            font-weight="600"
            font-size="16px"
            line-height="24px"
          >
            List of Log Activity
          </c-heading>
          <c-menu
            :close-on-select="false"
            @blur="true"
          >
            <c-menu-button
              ref="triggerFilter"
              border-radius="40px"
              background-color="superLightGray.900"
              pl="20px"
              pr="30px"
              py="10px"
              h="auto"
              color="gray.900"
              font-size="14px"
              line-height="21px"
              font-family="Roboto"
              font-weight="normal"
              :display="['none', 'flex']"
            >
              <c-image
                :src="require('@/assets/ic-filter.svg')"
                alt="icon"
                display="inline-block"
                margin-right="5px"
              />
              Filter
            </c-menu-button>
            <c-menu-list
              z-index="10"
              min-width="300px"
            >
              <c-box
                px="24px"
                mt="24px"
              >
                <c-form-control mb="24px">
                  <c-form-label
                    font-size="14px"
                    color="#555555"
                    font-weigh="400"
                    font-family="Roboto"
                  >
                    Start Date
                  </c-form-label>
                  <c-input-group size="md">
                    <c-input
                      v-model="startDate"
                      v-chakra="{
                        '::-webkit-calendar-picker-indicator': {
                          background: `url(${require('@/assets/icon-calendar-event.svg')}) no-repeat center`,
                          cursor: 'pointer',
                        },
                      }"
                      type="date"
                      error-border-color="red.300"
                      placeholder="Masukkan Start Date"
                    />
                  </c-input-group>
                </c-form-control>
                <c-form-control mb="24px">
                  <c-form-label
                    font-size="14px"
                    color="#555555"
                    font-weigh="400"
                    font-family="Roboto"
                  >
                    End Date
                  </c-form-label>
                  <c-input-group size="md">
                    <c-input
                      v-model="endDate"
                      v-chakra="{
                        '::-webkit-calendar-picker-indicator': {
                          background: `url(${require('@/assets/icon-calendar-event.svg')}) no-repeat center`,
                          cursor: 'pointer',
                        },
                      }"
                      type="date"
                      error-border-color="red.300"
                      placeholder="Masukkan End Date"
                    />
                  </c-input-group>
                </c-form-control>
              </c-box>
              <c-menu-divider />
              <c-flex justify="flex-end">
                <c-button
                  size="sm"
                  my="8px"
                  mr="16px"
                  @click.prevent="clearFilter"
                >
                  Clear
                </c-button>
                <c-button
                  variant-color="primary"
                  :is-disabled="!startDate && !endDate"
                  size="sm"
                  mr="24px"
                  my="8px"
                  @click.prevent="applyFilter"
                >
                  Apply
                </c-button>
              </c-flex>
            </c-menu-list>
          </c-menu>
        </c-flex>
        <c-divider
          mx="23px"
          orientation="vertical"
        />
        <c-box
          flex-basis="248px"
          align-self="center"
        >
          <c-form-control
            pos="relative"
            w="100%"
          >
            <c-image
              :src="require('@/assets/ic-search.svg')"
              alt="icon"
              pos="absolute"
              top="10px"
              left="15px"
              z-index="2"
            />
            <c-input
              id="search"
              v-model="search"
              v-chakra="{
                '&::placeholder': {
                  color: 'gray.900',
                },
              }"
              type="text"
              w="100%"
              placeholder="Pencarian"
              background="superLightGray.900"
              border-radius="6px"
              padding-left="50px"
              pos="relative"
              z-index="1"
              @keyup="onSearch"
            />
          </c-form-control>
        </c-box>
      </c-flex>

      <c-box>
        <c-grid
          w="100%"
          template-columns="repeat(7, 0fr)"
        >
          <c-box
            v-for="(item, index) in headings"
            :key="'heading-' + index"
            :w="item.width"
            py="11px"
            px="14px"
            color="primary.400"
            font-weight="bold"
            font-size="12px"
            line-height="18px"
            text-transform="uppercase"
            bg="rgba(0, 140, 129, 0.1)"
          >
            {{ item.label }}
          </c-box>
        </c-grid>

        <c-grid
          v-for="(item, idx) in items"
          :key="item.id"
          w="100%"
          max-w="100%"
          template-columns="repeat(7, 0fr)"
          border-bottom="1px solid #C4C4C4"
        >
          <c-box
            :w="headings[0].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
          >
            {{ numberingRow(idx) }}
          </c-box>
          <c-box
            :w="headings[1].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
          >
            {{ getDateAndTime(item.date) }}
          </c-box>
          <c-box
            :w="headings[2].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
          >
            {{ item.name }}
          </c-box>
          <c-box
            :w="headings[3].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
          >
            {{ item.ipAddress }}
          </c-box>
          <c-box
            :w="headings[4].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            text-transform="capitalize"
            word-break="break-word"
          >
            {{ item.type }}
          </c-box>
          <c-box
            :w="headings[5].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            word-break="break-word"
          >
            {{ item.activity }}
          </c-box>
        </c-grid>
        <c-flex
          v-if="!isEmpty"
          justify="space-between"
          py="28px"
          px="40px"
        >
          <c-flex align="center">
            <c-text
              color="gray.900"
              font-size="14px"
              line-height="21px"
            >
              Show:
            </c-text>
            <c-select
              v-model="perPage"
              min-w="120px"
              border-radius="6px"
              font-size="14px"
              ml="13px"
              size="md"
              @change="onSearch"
            >
              <option value="5">
                5 Rows
              </option>
              <option value="10">
                10 Rows
              </option>
              <option value="15">
                15 Rows
              </option>
              <option value="20">
                20 Rows
              </option>
            </c-select>
          </c-flex>

          <c-flex
            justify-content="flex-end"
            w="100%"
          >
            <Pagination
              :total-pages="totalPages"
              :per-page="parseInt(perPage)"
              :current-page="currentPage"
              :max-visible-buttons="3"
              @pagechanged="onChangePage"
            />
          </c-flex>
        </c-flex>
        <c-flex
          v-if="isEmpty"
          py="80px"
          px="20px"
          align="center"
          justify-content="center"
        >
          <c-box>
            <c-image
              :src="require('@/assets/empty-nutritionists.svg')"
              alt="empty"
              mx="auto"
            />
            <c-text
              color="gray.900"
              font-family="Roboto"
              font-size="24px"
              margin-top="20px"
            >
              Log Activity tidak ditemukan
            </c-text>
          </c-box>
        </c-flex>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import Pagination from '@/components/pagination'

export default {
  name: 'ManagementLogActivityPage',
  components: { Pagination },
  mixins: [generalMixin],
  data() {
    return {
      headings: [
        { label: 'no', width: '60px' },
        { label: 'date & time ', width: '190px' },
        { label: 'nama', width: '135px' },
        { label: 'ip', width: '115px' },
        { label: 'tipe', width: '115px' },
        { label: 'activity', width: '277px' },
      ],
      currentPage: 1,
      perPage: '5',
      search: this.$route.query.q ?? '',
      startDate: '',
      endDate: '',
      timer: null,
      isOpen: false,
    }
  },
  computed: {
    ...mapState({
      items: (s) => s.admLogActivity.items,
      meta: (s) => s.admLogActivity.meta,
    }),
    isEmpty() {
      return this.items.length === 0
    },
    totalPages() {
      return Math.ceil(this.meta?.total / parseInt(this.perPage))
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.currentPage = route.query.page ? parseInt(route.query.page) : 1
        let params = new URLSearchParams()
        params.set('q', this.search)
        params.set('start_date', this.startDate)
        params.set('end_date', this.endDate)
        params.set('page', this.currentPage)
        params.set('perpage', this.perPage)
        this.loadLogActivity(params)
      },
    },
  },
  methods: {
    ...mapActions({
      loadLogActivity: 'admLogActivity/listLogActivity',
    }),
    applyFilter() {
      this.onSearch()
      this.$refs.triggerFilter.$el.click()
    },
    clearFilter() {
      this.startDate = ''
      this.endDate = ''
      this.applyFilter()
    },
    onSearch() {
      this.currentPage = 1
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            q: this.search,
            start_date: this.startDate,
            end_date: this.endDate,
            page: this.currentPage,
            perpage: this.perPage,
          },
        })
      }, 800)
    },
    onChangePage(page) {
      this.currentPage = page
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.currentPage },
      })
    },
    numberingRow(index) {
      return this.currentPage * this.perPage - this.perPage + index + 1
    },
    getFormatDate(date) {
      moment.locale('id')
      return moment(date).format('D MMM yyyy')
    },
    getDateAndTime(date) {
      moment.locale('id')
      return moment(date).format('D MMM yyyy H:mm')
    },
  },
}
</script>
