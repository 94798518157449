var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    staticClass: "container",
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "font-size": "14px",
      "line-height": "21px",
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "is-current": ""
    }
  }, [_vm._v(" Manajemen Log Activity ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "my": "16px"
    }
  }), _c('c-flex', {
    attrs: {
      "mb": "33px",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px"
    }
  }, [_vm._v(" Manajemen Log Activity ")])], 1), _c('c-box', {
    staticClass: "container",
    attrs: {
      "background": "#FFFFFF",
      "border": "1px solid #F2F2F2",
      "box-shadow": "4px 4px 50px 5px rgba(0, 0, 0, 0.05)",
      "border-radius": "12px"
    }
  }, [_c('c-flex', {
    attrs: {
      "py": "25px",
      "px": "27px",
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "flex-grow": "1"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "600",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" List of Log Activity ")]), _c('c-menu', {
    attrs: {
      "close-on-select": false
    },
    on: {
      "blur": true
    }
  }, [_c('c-menu-button', {
    ref: "triggerFilter",
    attrs: {
      "border-radius": "40px",
      "background-color": "superLightGray.900",
      "pl": "20px",
      "pr": "30px",
      "py": "10px",
      "h": "auto",
      "color": "gray.900",
      "font-size": "14px",
      "line-height": "21px",
      "font-family": "Roboto",
      "font-weight": "normal",
      "display": ['none', 'flex']
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-filter.svg'),
      "alt": "icon",
      "display": "inline-block",
      "margin-right": "5px"
    }
  }), _vm._v(" Filter ")], 1), _c('c-menu-list', {
    attrs: {
      "z-index": "10",
      "min-width": "300px"
    }
  }, [_c('c-box', {
    attrs: {
      "px": "24px",
      "mt": "24px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "mb": "24px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Start Date ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '::-webkit-calendar-picker-indicator': {
          background: "url(".concat(require('@/assets/icon-calendar-event.svg'), ") no-repeat center"),
          cursor: 'pointer'
        }
      },
      expression: "{\n                      '::-webkit-calendar-picker-indicator': {\n                        background: `url(${require('@/assets/icon-calendar-event.svg')}) no-repeat center`,\n                        cursor: 'pointer',\n                      },\n                    }"
    }],
    attrs: {
      "type": "date",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Start Date"
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "24px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" End Date ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '::-webkit-calendar-picker-indicator': {
          background: "url(".concat(require('@/assets/icon-calendar-event.svg'), ") no-repeat center"),
          cursor: 'pointer'
        }
      },
      expression: "{\n                      '::-webkit-calendar-picker-indicator': {\n                        background: `url(${require('@/assets/icon-calendar-event.svg')}) no-repeat center`,\n                        cursor: 'pointer',\n                      },\n                    }"
    }],
    attrs: {
      "type": "date",
      "error-border-color": "red.300",
      "placeholder": "Masukkan End Date"
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1)], 1)], 1), _c('c-menu-divider'), _c('c-flex', {
    attrs: {
      "justify": "flex-end"
    }
  }, [_c('c-button', {
    attrs: {
      "size": "sm",
      "my": "8px",
      "mr": "16px"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearFilter.apply(null, arguments);
      }
    }
  }, [_vm._v(" Clear ")]), _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "is-disabled": !_vm.startDate && !_vm.endDate,
      "size": "sm",
      "mr": "24px",
      "my": "8px"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.applyFilter.apply(null, arguments);
      }
    }
  }, [_vm._v(" Apply ")])], 1)], 1)], 1)], 1), _c('c-divider', {
    attrs: {
      "mx": "23px",
      "orientation": "vertical"
    }
  }), _c('c-box', {
    attrs: {
      "flex-basis": "248px",
      "align-self": "center"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "pos": "absolute",
      "top": "10px",
      "left": "15px",
      "z-index": "2"
    }
  }), _c('c-input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '&::placeholder': {
          color: 'gray.900'
        }
      },
      expression: "{\n              '&::placeholder': {\n                color: 'gray.900',\n              },\n            }"
    }],
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "placeholder": "Pencarian",
      "background": "superLightGray.900",
      "border-radius": "6px",
      "padding-left": "50px",
      "pos": "relative",
      "z-index": "1"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1), _c('c-box', [_c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": "repeat(7, 0fr)"
    }
  }, _vm._l(_vm.headings, function (item, index) {
    return _c('c-box', {
      key: 'heading-' + index,
      attrs: {
        "w": item.width,
        "py": "11px",
        "px": "14px",
        "color": "primary.400",
        "font-weight": "bold",
        "font-size": "12px",
        "line-height": "18px",
        "text-transform": "uppercase",
        "bg": "rgba(0, 140, 129, 0.1)"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1), _vm._l(_vm.items, function (item, idx) {
    return _c('c-grid', {
      key: item.id,
      attrs: {
        "w": "100%",
        "max-w": "100%",
        "template-columns": "repeat(7, 0fr)",
        "border-bottom": "1px solid #C4C4C4"
      }
    }, [_c('c-box', {
      attrs: {
        "w": _vm.headings[0].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.numberingRow(idx)) + " ")]), _c('c-box', {
      attrs: {
        "w": _vm.headings[1].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.getDateAndTime(item.date)) + " ")]), _c('c-box', {
      attrs: {
        "w": _vm.headings[2].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('c-box', {
      attrs: {
        "w": _vm.headings[3].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center"
      }
    }, [_vm._v(" " + _vm._s(item.ipAddress) + " ")]), _c('c-box', {
      attrs: {
        "w": _vm.headings[4].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center",
        "text-transform": "capitalize",
        "word-break": "break-word"
      }
    }, [_vm._v(" " + _vm._s(item.type) + " ")]), _c('c-box', {
      attrs: {
        "w": _vm.headings[5].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center",
        "word-break": "break-word"
      }
    }, [_vm._v(" " + _vm._s(item.activity) + " ")])], 1);
  }), !_vm.isEmpty ? _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "py": "28px",
      "px": "40px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "gray.900",
      "font-size": "14px",
      "line-height": "21px"
    }
  }, [_vm._v(" Show: ")]), _c('c-select', {
    attrs: {
      "min-w": "120px",
      "border-radius": "6px",
      "font-size": "14px",
      "ml": "13px",
      "size": "md"
    },
    on: {
      "change": _vm.onSearch
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  }, [_c('option', {
    attrs: {
      "value": "5"
    }
  }, [_vm._v(" 5 Rows ")]), _c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10 Rows ")]), _c('option', {
    attrs: {
      "value": "15"
    }
  }, [_vm._v(" 15 Rows ")]), _c('option', {
    attrs: {
      "value": "20"
    }
  }, [_vm._v(" 20 Rows ")])])], 1), _c('c-flex', {
    attrs: {
      "justify-content": "flex-end",
      "w": "100%"
    }
  }, [_c('Pagination', {
    attrs: {
      "total-pages": _vm.totalPages,
      "per-page": parseInt(_vm.perPage),
      "current-page": _vm.currentPage,
      "max-visible-buttons": 3
    },
    on: {
      "pagechanged": _vm.onChangePage
    }
  })], 1)], 1) : _vm._e(), _vm.isEmpty ? _c('c-flex', {
    attrs: {
      "py": "80px",
      "px": "20px",
      "align": "center",
      "justify-content": "center"
    }
  }, [_c('c-box', [_c('c-image', {
    attrs: {
      "src": require('@/assets/empty-nutritionists.svg'),
      "alt": "empty",
      "mx": "auto"
    }
  }), _c('c-text', {
    attrs: {
      "color": "gray.900",
      "font-family": "Roboto",
      "font-size": "24px",
      "margin-top": "20px"
    }
  }, [_vm._v(" Log Activity tidak ditemukan ")])], 1)], 1) : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }